<template>
  <!--  for desktop view-->
  <div id="sticky-banner" tabindex="-1"
       class="hidden relative lg:flex w-full dark:bg-gray-700 dark:border-gray-600 items-center"
       @click="handleBannerClick"
  >
    <div class="flex max-w-screen">
      <span class="inline-flex p-1 rounded-full items-center justify-center flex-shrink-0 bg-banner-icon-color dark:bg-banner-icon-color">
        <img src="~/assets/icon/announcement_banner.png" :style="{ width: `25px`, height: `25px`}" />
      </span>
      <div class="text-sm ml-3 flex items-center justify-center"
           :style="{ maxWidth: '30%'}">
        <p class="font-semibold text-white  text-slate-950 Truncate line-clamp-1"
        >{{ announcementText }}</p>
      </div>

      <div class="flex items-center justify-center text-sm text-gray-300 dark:text-gray-400 ml-3"
           :style="{ maxWidth: '40%'}"
      >
        <p class="Truncate text-zinc-100 line-clamp-1"
        >{{ descriptionText }}</p>
      </div>
    </div>
    <div class="flex items-center mr-4 absolute right-0">
      <div class="ml-4 flex items-center text-sm text-gray-300 dark:text-gray-400">
        <p class="text-white mr-2" v-if="announcementEndDate && announcementEndDate.length > 0">Ends on: {{ convertDBDateToDateTime(announcementEndDate) }}</p>
        <p class="text-white mr-2" v-else>Ends on: Unscheduled</p>
        <button
            type="button"
            class="mt-1 px-1 py-1 text-white hover:text-white border border-white hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm text-center me-2 mb-2 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400 dark:focus:ring-yellow-900"
            @click.stop="onEndNowBtnClick()"
        >
          End Now
        </button>
      </div>
      <button
          data-dismiss-target="#sticky-banner"
          type="button"
          class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-white hover:bg-white hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
          @click.stop="closeBanner(bannerIndex)"
      >
        <Icon name="bitcoin-icons:cross-outline" class="w-6 h-6"/>
        <span class="sr-only">Close banner</span>
      </button>
    </div>
  </div>

  <ConfirmationDialogue :key="confirmationDialogueOpen" :fire="confirmationDialogueOpen"
                        :on-close-dialogue="()=>{confirmationDialogueOpen=false}"
                        title="Are you sure?" confirm-button-text="End Now"
                        :on-confirm="()=>{endBannerNow()}"/>
</template>



<style scoped>
</style>
<script setup lang="ts">

import {
  announcementPageRefreshEvent,
  announcementBannerCloseEvent,
  notificationDetailDialogue,
  useGlobalEvent
} from "~/composables/useEventBus";
import {onMounted, ref} from "vue";
import moment from "moment";

import {announcementStore} from "~/store/announcementStore";
import {userStore} from "~/store/userStore";
import {convertDBDateToDateTime, convertToUTCDateTime, convertUTCTimeToLocalTimezone} from "~/utils/helpers";
import {AnnouncementStatus} from "~/utils/enums/AnnouncementStatus";

const { bannerIndex, announcementId, announcementText, descriptionText, announcementStartDate, announcementEndDate } = defineProps(['bannerIndex','announcementId', 'announcementText', 'descriptionText', 'announcementStartDate', 'announcementEndDate']);

const useUserStore = userStore();
const useAnnouncementStore = announcementStore();

const { loggedInUser } = useUserStore;
const { updateAnnouncementByAnnouncementId } = useAnnouncementStore;
const confirmationDialogueOpen = ref<boolean>(false)
const mobileBannerHeight = 80;
const handleBannerClick = () => {
  useGlobalEvent(notificationDetailDialogue(), <any>{ announcement: announcementText, description: descriptionText, startDate: announcementStartDate, endDate: announcementEndDate });
}

const onEndNowBtnClick = () => {
  confirmationDialogueOpen.value = true;
}

const endBannerNow = () => {
  // Expiring announcement
  try {
    const updateAnnouncementRb = {
      ThreadId: announcementId,
      ThreadName: announcementText,
      Text: descriptionText,
      EndDate: convertToUTCDateTime(moment().format('YYYYMMDDHHmm')),
      Status: AnnouncementStatus.EXPIRED,
      st: loggedInUser.SessionToken
    }
    updateAnnouncementByAnnouncementId(updateAnnouncementRb);
    useGlobalEvent(announcementPageRefreshEvent(), {});
    useGlobalEvent(announcementBannerCloseEvent(), { bannerIndex, announcementId });
  } catch(error) {
    console.log("Error from - Banner > endBannerNow: ", error)
  }

}

const closeBanner = () => {
  useGlobalEvent(announcementBannerCloseEvent(), { bannerIndex, announcementId });
}


onMounted(() => {
})
</script>