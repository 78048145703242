<template>
  <div v-if="getCompanyLogo" class="flex flex-row space-x-2 items-center">
    <img class="max-h-14 max-w-40" :src="getCompanyLogo" alt="" />
  </div>
</template>

<script setup lang="ts">
import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";

const useCompanyStore = companyStore();
const {getCompanyLogo} = storeToRefs(useCompanyStore);
</script>

